<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle; '>

		        <div :style="'vertical-align: middle; color: '+getFGColor('#000','#88e')+'; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'">PROFILE MANAGER 1.1</div>

		        <br/>
		        <br/>

				<br/>
		    	<span class='SVcontrolLabel'>Selected Profile: 
		    	      <span class='dontWrap bold invers'>{{selectedProfile.id?selectedProfile.label:"--"}}</span>
		    	</span>
		    	<br/>
		    	<br/>

		    	<span class='SVcontrolLabel'><b>Filter for rights</b></span>
		    	<vSelect placeholder='choose setup/settings' :multiple="true" :options="allModuls" style='width:100%' v-model="selectedModuls" @input='filter()'>
			          
			    </vSelect>
			    <vSelect placeholder='choose screen' :multiple="true" :options="allScreens" style='width:100%' v-model="selectedScreens" @input='filter()'>
			          
			    </vSelect>
			    <vSelect placeholder='choose type' :multiple="true" :options="allActions" style='width:100%' v-model="selectedActions" @input='filter()'>
			          
			    </vSelect>
			    
		    	
		    	<br/>
		    	<span class='SVcontrolLabel'>Only assigned rights: </span>
		    	&nbsp;
		    	<app-switch v-model="onlyUsedRights" :checked="onlyUsedRights"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Only not assigned rights: </span>

		    	<app-switch v-model="onlyNonUsedRights" :checked="onlyNonUsedRights"/>

		    	<br/>
		    	<hr/>
		    	<span class='SVcontrolLabel'>Show rights: </span>
		    	&nbsp;
		    	<app-switch v-model="showRights" :checked="showRights"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Show users: </span>
		    	&nbsp;
		    	<app-switch v-model="showUsers" :checked="showUsers"/>
		    	<hr/>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>
		    	
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 79.8%;'> 
	    <!-- HEADER -->
	    <div class='dontWrap' style='float: top; width: 100%; height: 36pt; border-bottom: 1px solid #cdc;'>
	   
			<div style='width: 50%;  overflow-y: scroll;'>
			    	
					<span style='display: flex; float: left; padding-left: 5pt; padding-right: 5pt;'>
				     <b>Profiles</b>
				     </span>
				     <span style='display: flex; float: right; padding-left: 5pt;'>
				        <input style='height: 24pt; margin-top:4pt;' @focus="$event.target.select()"
					       type='text' size="12" v-model="searchStringProfile" placeholder="search profile..."/>
				        <button v-show='selectedProfile && selectedProfile.id' title='assign all displayed rights to the selected profile' 
				                class='myButton transition' @click="setAllRights()">Assign all rights</button>
						<button v-show='selectedProfile && selectedProfile.id' title='remove all rights from the selected profile' 
						        class='myButton transition' @click="remove(selectedProfile)">Remove rights</button>
				     </span>
		    </div>
		    <div :style='getRightsMainStyle(true)' class='transition'>
		    	 <span style='display: flex; float: left; padding-left: 5pt; padding-right: 5pt;'>
			     <b>Rights</b>
			     </span>
			     <span class='transition' style='display: flex; float: right; padding-left: 20pt;'>
					<input style='height: 24pt; margin-top:4pt;' @focus="$event.target.select()"
					       type='text' size="12" v-model="searchString" placeholder="search right..."/>
					
			     </span>
		    </div>
		    <div :style='getUserMainStyle(true)' class='transition'>
		    	 <span style='display: flex; float: left; padding-left: 5pt; padding-right: 5pt;'>
			     <b>Users</b>
			     </span>
			     <span class='transition' style='display: flex; float: right; padding-left: 5pt;'>
			        <input style='height: 24pt; margin-top:4pt;' size="12" @focus="$event.target.select()"
					       type='text' v-model="searchStringUser"  placeholder="search user..."/>
					
					
		
			     </span>
		    </div>
		     
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: 100%;' class='dontWrap'>
		    <div style='width: 50%; height: calc(100vh - 160px); overflow-y: scroll;'
		        
		      >
		    	<div v-for="(profile,idx) in filterProfile(profiles)" :key="'pro'+idx" 
		    	     @click="select(profile)" 
		    	     @dragstart="startDragProfile($event, profile)" draggable
			         @drop.exact="onDropProfile($event, profile, 'copy')"
			         @drop.ctrl="onDropProfile($event, profile, 'remove')"
			         @dragover.prevent
					 @dragenter.prevent
					 @contextmenu="menuRClicked($event, profile, null)"
					 :title="identity('click to select this profile, use drag and drop to copy rights')"
		    	     class='profile todrag'
		    	     :style='getProfileStyle(profile)'>
		    		{{ profile.label }}
				</div>
	
	    	</div>  <!-- TABLE -->
	    	<div :style='getRightsMainStyle()' class='transition'>          
			    	<div v-for="(right,idx) in filter()" :key="'rgt'+idx" 
			    	     @click="selectRight($event, right)" 
			    	     :title="identity('if a profile is selected, assign or remove this right by click')"
			    	     @contextmenu="menuRClicked($event, null, right)"
			    	     class='right'
			    	     :style="right.style">
			    	
		    			<div style='display: inline-flex; width: 40%;'>{{moduls[right.moduleId].label}}</div>
		    			<div style='display: inline-flex; width: 60%;'>{{right.name}}</div>
					</div>
				
		    </div>
		    <div :style='getUserMainStyle()' class='transition'
				  >
			    	<div v-for="(user,idx) in filterUser(users)" :key="'ust'+idx" 
			    	     @click="selectUser($event, user)"
			    	     @contextmenu="menuRClicked($event, null, null, user)"
			    	     @dragstart="startDragUser($event, user)" draggable
			             @drop.exact="onDropUser($event, user, 'copy')"
			             @drop.ctrl="onDropUser($event, user, 'remove')"
			             @dragover.prevent
					     @dragenter.prevent
			    	     class='right transitionFast'
			    	     :title="identity('if a profile is selected, assign or remove the selected profile to this user by click')"
			    	     :style='getUserStyle(user)'
			    	     :class='getUserClass(user)'
			    	     >

		    			<div style='display: inline-flex;'>{{user.login}}</div>
					</div>
				
		    </div>
		    <GConfirm ref='confirm'/>
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    <GFWEditorRouter v-show='showEditor' @update="update" @insert="update" @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :dataId="dataId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>
    
    <ContextMenu ref="menu" offsetY="-60">
      <template v-if="contextData" slot-scope="{ contextData }">
        <ContextMenuItem v-if="contextData.profile && contextData.profile.id"   @clicked="openProfile(event, contextData.profile)">
          Open profile {{contextData.profile.label}}
        </ContextMenuItem>
       <ContextMenuItem v-if="contextData.profile"   @clicked="newProfile(event, contextData.profile)">
          New profile 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.right && contextData.right.id"   @clicked="openRight(event, contextData.right)">
          Open right  {{contextData.right.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.right"   @clicked="newRight(event, contextData.right)">
          New right 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.user"   @clicked="openUser(event, contextData.user)">
          Open user  {{contextData.user.login}}
        </ContextMenuItem>
        
        <ContextMenuSep/>
         <ContextMenuItem v-if="contextData.profile"   @clicked="getUsers('profile', contextData.profile.id)">
          Show user having {{contextData.profile.label}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.right"   @clicked="getMappedProfiles('rights', contextData.right.id)">
          Show profiles having {{contextData.right.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.right"   @clicked="getUsers('rights', contextData.right.id)">
          Show user having {{contextData.right.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.user"   @clicked="getMappedProfiles( 'user', contextData.user.id)">
          Show profiles for user {{contextData.user.login}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.user"   @clicked="getMappedRights('user', contextData.user.id)">
          Show rights for user {{contextData.user.login}}
        </ContextMenuItem>
       </template>
    </ContextMenu>

    <ProgressBar v-if="showProgressBar" generalInfo="progress request" :action=pbAction @action=action></ProgressBar>
 </div>
</template>

<script>
import {HTTP, fwAPI, rightsAPI, reportAPI, setReload, myLocale, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor, isDarkMode } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick'; 
import GFWTreeView from '@/components/GFWTreeView';
import ProgressBar from '@/components/ProgressBar';
import GConfirm from '@/components/GConfirm';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { D3LineChart } from 'vue-d3-charts';
var timers = [];

export default {
  name: 'GFW Schedule View',
  components : {
   'app-switch': Switch, vSelect, ContextMenu, ContextMenuSep, ContextMenuItem, ProgressBar, GConfirm
  },
  data () {
    return {
      
      profiles: [],
      allProfiles: [],
      rights: [],
      allRights: [],
      moduls: [],
      allModuls: [],
      allScreens: [],
      allActions: [],
      rightMapping: [],
      mappedRights: [],
      mappedProfiles: [],
      mappedUsers: [],
      selectedProfile: {},
      selectedModuls: [],
      selectedActions: [],
      selectedScreens: [],
      users: [],
      showUsers: false,
      showRights: true,
      showProgressBar: false,
      pbAction: "",
      
      dndFromProfile: {},
      dndToProfile: {},
      
      controlsRight: false,
      onlyUsedRights: false,
      onlyNonUsedRights: false,
      rights2DataMap: {},
      user2DataMap: {},
      profile2DataMap: {},
     
      searchString: "",
      searchStringUser: "",
      searchStringProfile: "",
      showEditor: false,
      entityMap: {},
      mapping: [],
      metadata: {},
      rightsMetadata: {},
      userMetadata: {},
      profileMetadata: {},
      dataId: 0,
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      
      copyFromId: 0,
      copyToProfile: {},
      getFGColor,
    }
  },    
  methods: {
    myLocale() { return myLocale();},
    identity(x) { return x;},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 20%; height: calc(100vh - 120px); padding: 2ex; border-left: 1px solid #ccc;"
    	}
    	return "float: left; width: 20%; height: calc(100vh - 120px); padding: 2ex; border-right: 1px solid #ccc;"
    },
    getUserMainStyle(header) 
    {
    	let h = (header)?"":"height: calc(100vh - 160px);";
    	let w = 30;
    	if ( !this.showRights )
    	{
    		w = 50;
    	}
    	if ( this.showUsers )
    	{
    		return h+"width: "+w+"%; overflow-y: scroll;"
    	}
    	return h+"width: 0%; overflow-y: scroll;"
    	 
    },
    getRightsMainStyle(header)
    {
    	//style='width: 50%; float: top; overflow-y: scroll;' 
    	let h = (header)?"":"height: calc(100vh - 160px);";
    	if ( this.showRights )
    	{
    		return h+"width: 50%; float: top; overflow-y: scroll;"
    	}
    	return h+"width: 0%; float: top; overflow-y: scroll;"
    },
    open(menu)
    {
    	let item = "/fw/"+menu.entity;
    	this.$router.replace(item);
    },
    nodeClicked( module, data, metadata)
    {
    	this.nodeToOpen( metadata, data)
    	
    },
    menuRClicked(event, profile, right, user)
    {
        event.preventDefault();
        this.$refs.menu.open(event, {profile:profile, right:right, user: user} )
    },
    closeMenu() { try { this.$refs.menu.close(); } catch (e) { /* */ } },
    filter() {
    	let tmp = this.allRights;
    	let that = this;
    	let useSearch = this.searchString.toLowerCase();
    	if ( useSearch !== "" )
        {
        	tmp =  tmp.filter(r => r.name.toLowerCase().indexOf( useSearch) >= 0 || that.moduls[r.moduleId].label.toLowerCase().indexOf( useSearch) >= 0)
        }
    	if ( this.selectedModuls && this.selectedModuls.length )
    	{
    		let myFilter = new Array();
    		for ( var i in this.selectedModuls )
    		{
    			myFilter.push( this.selectedModuls[i].id )
    		}
    		tmp =  tmp.filter(r => myFilter.includes(r.moduleId) );
    		
    	}
    	//alert( JSON.stringify(this.selectedScreens));
    	if ( this.selectedScreens && this.selectedScreens.length )
    	{
    		tmp =  tmp.filter(r => r.screen && this.selectedScreens.includes(r.screen) );
    	}
    	if ( this.selectedActions && this.selectedActions.length )
    	{
    		let myFilter = new Array();
    		for ( i in this.selectedActions )
    		{
    			myFilter.push( this.selectedActions[i].id )
    		}
    		tmp =  tmp.filter(r => myFilter.includes(r.action) );
    		
    	}
    	if ( this.onlyUsedRights )
        {
        	tmp = tmp.filter( r => r.style)
        }
        if ( this.onlyNonUsedRights )
        {
        	tmp = tmp.filter( r => !r.style)
        }
    	return tmp;
    },
    filterUser(users) {
    	let tmp = users;
    	if ( this.searchStringUser !== "" )
        {
        	tmp =  tmp.filter(r => r.login.toLowerCase().indexOf( this.searchStringUser.toLowerCase()) >= 0 )
        }
    	
    	return tmp;
    },
    filterProfile( profile) {
    	let tmp = profile;
    	if ( this.searchStringProfile !== "" )
        {
        	tmp =  tmp.filter(r => r.label.toLowerCase().indexOf( this.searchStringProfile.toLowerCase()) >= 0 )
        }
    	
    	return tmp;
    },
    getProfileStyle( profile) {
    	if ( this.mappedProfiles[ profile.id] === true )
    	{
    		return "background-color: #ccf; color: #000;";
    	}
    	if ( this.selectedProfile && this.selectedProfile.id === profile.id )
    	{
    		return "background-color: #ded; color: #000;";
    	}
    	return "";
    },
    getUserStyle( user) {
    	//console.log("getUserStyle("+ user.id+")");
    	//console.log( JSON.stringify(this.mappedUsers));
    	if ( this.mappedUsers[ user.id] === true )
    	{
    		//console.log("getUserStyle("+ user.id+") --> TRUE" );
    		return "background-color: #ded; color: #000;";
    	}
    	return "";
    },
    getUserClass( user) {
    	let cl = "";
    	if ( user.admin === true )
    	{
    		//console.log("getUserStyle("+ user.id+") --> TRUE" );
    		cl += "admin ";
    	}
    	if ( ! user.allowed  )
    	{
    		//console.log("getUserStyle("+ user.id+") --> TRUE" );
    		cl += "disabledUser ";
    	}
    	return cl;
    },
    getRightStyle( rightId ) {
        
        
    	if ( this.mappedRights[ rightId] === true )
    	{
    		return "background-color: #ded; color: #000;";
    	}
    	return "";
    },
    startDragProfile( evt, profile ) {
    	evt.dataTransfer.setData('profileId', profile.id)
    },
    startDragUser( evt, user ) {
    	evt.dataTransfer.setData('userId', user.id)
    },
    onDropProfile(evt, profile, type) 
    {
        const profileID = evt.dataTransfer.getData('profileId');
        let that = this;
        if ( profileID )
        {
	        //alert( JSON.stringify(this.profiles));
	        this.dndFromProfile = this.profiles.find( p => p.id == profileID);
	        //alert( JSON.stringify(this.dndFromProfile));
	        this.dndToProfile = profile;
	        if ( type === 'copy')
	        {
	        	//this.$vm2.open('modal-copyConf');
	        	let msg = 'Copy rights from '+this.dndFromProfile.label+' to '+profile.label;
	        	this.$refs.confirm.confirm( {text: msg, title: "Copy rights", button1: "Cancel", button2: "Copy"}).then(x => {
	        		that.copyFromId = that.dndFromProfile.id;
			  		that.copyToProfile = that.dndToProfile;
			  	    that.pbAction = "doCopy";
			  	    that.showProgressBar = true;
		       }).catch((err) => { 
		       		
		       });
	        }
	        if ( type === 'remove')
	        {
	        	//this.$vm2.open('modal-copyConf');
	        	let msg = 'Remove rights from '+this.dndFromProfile.label+' at '+profile.label;
	        	this.$refs.confirm.confirm( {text: msg, title: "Copy rights", button1: "Cancel", button2: "Remove"}).then(x => {
	        		that.copyFromId = that.dndFromProfile.id;
			  		that.copyToProfile = that.dndToProfile;
			  	    that.pbAction = "unCopy";
			  	    that.showProgressBar = true;
		       }).catch((err) => { 
		       		
		       });
	        }
	    }
    },
    onDropUser(evt, user, type) 
    {
        const userId = evt.dataTransfer.getData('userId');
        if ( userId )
        {
	        //alert( JSON.stringify(this.profiles));
	        let fromUser = this.users.find( p => p.id == userId);
	        //alert( JSON.stringify(this.dndFromUser));
	        //this.dndToUser = user;
	        let toUser = user;
	        let buttonArr = new Array();
			let that = this;
			buttonArr.push( { text: 'Copy settings', onClick: function(){
	            that.copyfromUser(fromUser, toUser, 'Settings')
	        }});
			buttonArr.push( { text: 'Copy profiles', onClick: function(){
				that.copyfromUser(fromUser, toUser, 'Profiles')
		    }});
	        buttonArr.push( { text: 'Copy settings & profiles', onClick: function(){
	            that.copyfromUser(fromUser, toUser, 'SettingsAndProfiles')
	        }});
	        buttonArr.push( { text: 'Cancel', onClick: function(){
						            
						          }});
	    	this.$iosAlertView({
		      title: 'Copy from user: '+fromUser.login,
		      text: 'to: '+user.login,
		      buttons: buttonArr
		    });
	        
	        
			
	    }
    },
    copyfromUser(fromUser, toUser, what)
    {
        let that = this;
    	HTTP.put( rightsAPI+"/copyUserSettings/"+what+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromUser.id+"/"+toUser.id)
         .then( response => 
         {
            that.$toast.success(what+" copied to user: " + toUser.login, 'Ok', { position: "topRight" });

         }).catch(e => {
                showError( that.$toast, "copy user settings", e);
            });   
    },
    closeCopyConfPU() { 
      	this.$vm2.close('modal-copyConf');
      },
    closeRemoveConfPU() { 
      	this.$vm2.close('modal-removeConf');
    },
    select( profile) 
    {
    	this.mappedProfiles = new Array();
    	//this.users = new Array(); // close user-view
    	if ( this.selectedProfile && this.selectedProfile.id === profile.id )
    	{
    		this.selectedProfile = [];
    		this.mappedRights = [];
    		this.allRights.forEach(function(v){ delete v.style });
    		this.mappedUsers = [];
    	}
    	else
    	{
    		this.selectedProfile = profile;
    		this.getMappedRights( 'profile', profile.id);
    		if ( this.showUsers )
    		{
    			this.getUsers('profile', profile.id)
    		}
    	}
    },
    selectUser( event, user )
    {
    	if ( this.selectedProfile && this.selectedProfile.id )
    	{
    		if ( ! (this.mappedUsers[ user.id] === true))
	    	{
	    		this.assignUser( this.selectedProfile.id, user.id, true);
	    	}
	    	else
	    	{
	    		this.assignUser( this.selectedProfile.id, user.id, false);
	    	}
	    }
	    else
	    {
	    	//this.openUser( event, user);
	    	this.getMappedRights('user', user.id)
	    	this.getMappedProfiles( 'user', user.id)
	    }
    },
    selectRight( event, right ) {
    	
    	if ( this.selectedProfile && this.selectedProfile.id )
    	{
    		if ( ! (this.mappedRights[ right.id] === true))
	    	{
	    		this.assign( this.selectedProfile.id, right.id, true);
	    	}
	    	else
	    	{
	    		this.assign( this.selectedProfile.id, right.id, false);
	    	}
	    }
	    else
	    {
	    	//this.openRight( event, right);
	    	this.getMappedProfiles( 'rights', right.id);
	    	if ( this.showUsers )
	    	{
	    		this.getUsers('rights', right.id)
	    	}
	    }
    },
    openProfile( event, p )
    {
        this.closeMenu();
    	let ID = p.id;
    	let profile = this.allProfiles.find( x => x.id == ID );
    	this.mapping = [ {row: 0, dataId: profile.id, rId: 0, visible: true}];
    	this.allEntities = [ profile];
    	this.selectedRecord = {};
    	this.entityMap = this.profile2DataMap;
    	this.metadata = this.profileMetadata;
        Object.assign( this.selectedRecord, profile);
		this.dataId = profile.id;
    	this.showEditor = true;

    },
    openUser( event, user )
    {
        this.closeMenu();
    	this.mapping = [ {row: 0, dataId: user.id, rId: 0, visible: true}];
		this.dataId = user.id;
		//this.selectedRecord = {};
    	//alert( user.login +"/"+user.id);
    	let that = this;
		HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/User/allEntitiesWithDate/null/true/1", [user.id])
	        		 .then( response => 
	                 {

	               		that.allEntities = response.data.data;
	               		that.selectedRecord = that.allEntities[0];
	                    Object.assign( that.selectedRecord, that.allEntities[0]);
	                    //alert( that.selectedRecord.login +"/"+that.selectedRecord.id);
	                    that.metadata = response.data.metadata;
	                    that.entityMap = response.data.entity2DataMap;
	                    //alert( JSON.stringify(that.entityMap));
	                    that.showEditor = true;      
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    
    },
    
    newProfile() {
        this.closeMenu();
        this.selectedRecord = {};
   		this.entityMap = this.profile2DataMap;
    	this.metadata = this.profileMetadata;
   		HTTP.get( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/Profile/"+0)
                    .then( response => {
                    this.selectedRecord = response.data;
                }).catch(e => {
                   this.error("Can't create new record ("+this.MODULE+") ", e);
                });
        this.showEditor = true;
    
      //console.log(this.entities.orig[rowIndex]['flag']);
    },
    newRight() {
        this.closeMenu();
        this.selectedRecord = {};
   		this.entityMap = this.rights2DataMap;
    	this.metadata = this.rightsMetadata;
   		HTTP.get( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/Right/"+0)
                    .then( response => {
                    this.selectedRecord = response.data;
                }).catch(e => {
                   this.error("Can't create new record ("+this.MODULE+") ", e);
                });
        this.showEditor = true;
    
      //console.log(this.entities.orig[rowIndex]['flag']);
    },
    openRight( event, right )
    {
		this.closeMenu();
        this.mapping = [{row: 0, dataId: right.id, rId: 0, visible: true}];
    	this.allEntities = [ right];
    	this.selectedRecord = {};
    	this.entityMap = this.rights2DataMap;
    	this.metadata=this.rightsMetadata;
        Object.assign( this.selectedRecord, right);
		this.dataId = right.id;
    	this.showEditor = true;
    	
    },
    closeEditor()
    {
         this.showEditor = false;
    },
    focusInput() {
      this.$refs.entersearch.focus();
    },
   
    getProfiles() {
        let that = this;
		
        let time = new Date().getTime();
    	let profileLoad = new Promise(function (resolve, reject) {
            //console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Profile/asOptions/true/0");
            HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Profile/allEntities/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		//console.log("LOADED allProfiles in " + dur +"ms");  
                    var allProfiles = response.data.data;
                    that.profiles = [];
                    for ( var profile in allProfiles )
                    {
                    	that.profiles.push( {id: allProfiles[profile].id, label: allProfiles[profile].name, visible: true});
                    }   
                    that.allProfiles = response.data.data;
                    that.profileMetadata = response.data.metadata;
                    that.profile2DataMap = response.data.entity2DataMap;
                    
                    resolve (that.allProfiles);                    
                 }).catch(e => {
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
       		
	      });
    },
    getMappedRights(what, id) {
        let that = this;
		that.mappedRights = new Array();
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/getRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/getRights/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+id);
       		HTTP.get( rightsAPI+"/getRights/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+id)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("LOADED getMappedRights in " + dur +"ms");  
                 	that.rightMapping = response.data;
                 	
                 	//xxxx that.allRights

                 	that.allRights.forEach(function(v){ delete v.style });
                 	for ( var idx in that.rightMapping )
                 	{
                 		that.mappedRights[ that.rightMapping[idx].rightId ] = true;
                 		that.allRights.find(p=>p.id === that.rightMapping[idx].rightId ).style="background-color: #ded; color: #000;";
                 	}
                 	that.$forceUpdate();
                    resolve (that.rightMapping);                    
                 }).catch(e => {
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    getUsersMD(option) {
        let that = this;
        this.closeMenu();
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
	    	if ( option === 0 )
	    	{
		    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/User/allEntitiesWithDate/null/true/0", [0])
		        		 .then( response => 
		                 {
		                 	let dur = (new Date().getTime()-time);
		               		//console.log("LOADED rights in " + dur +"ms");  
		                    
		                    that.userMetadata = response.data.metadata;
		                    that.user2DataMap = response.data.entity2DataMap;
		                    
		                          
		                 }).catch(e => {
		                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
		
		                    });   
		    }
		    else
		    {
		    	HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/User/allEntities/true/0")
		        		 .then( response => 
		                 {
		                 	let dur = (new Date().getTime()-time);
		               		//console.log("LOADED rights in " + dur +"ms");  
		                    
		                    that.userMetadata = response.data.metadata;
		                    that.user2DataMap = response.data.entity2DataMap;
		                    that.users = response.data.data;
		                    //alert( that.users.length);      
		                 }).catch(e => {
		                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
		
		                    });   
		      }
	    	});
	    
    },
    getUsers(what, id) {
        let that = this;
		this.closeMenu();
		this.showUsers = true;
		this.mappedUsers = new Array();
		
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/getUsers/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/getUsers/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+id);
       		HTTP.get( rightsAPI+"/getUsers/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+id)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("LOADED getMappedRights in " + dur +"ms");  
                 	var usersFound = response.data;
                 	//alert(JSON.stringify(usersFound)); 
                 	for ( var idx in usersFound )
                 	{
                 		that.mappedUsers[ usersFound[idx].id ] = true;
                 	}
                 	//alert(JSON.stringify(that.mappedUsers)); 
                 	that.$forceUpdate();
                    resolve (that.mappedUsers);                    
                 }).catch(e => {
                        that.$toast.error("loading data for users: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    getMappedProfiles(what, id) {
        let that = this;
		that.mappedProfiles = new Array();
        let time = new Date().getTime();
        this.selectedProfile = {};
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/getRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/getProfileForRights/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+id);
       		HTTP.get( rightsAPI+"/getProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+what+"/"+id)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("LOADED getMappedRights in " + dur +"ms");  
                 	var profiles = response.data;
                 	
                 	for ( var idx in profiles )
                 	{
                 		that.mappedProfiles[ profiles[idx].id ] = true;
                 	}
                 	
                 	that.$forceUpdate();
                    resolve (that.mappedProfiles);                    
                 }).catch(e => {
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    assign(profileId, rightId, flag) {
        let that = this;
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/getRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/setRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profileId+"/"+rightId+"/"+flag);
       		HTTP.put( rightsAPI+"/setRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profileId+"/"+rightId+"/"+flag)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("SET setMappedRights in " + dur +"ms");  
					if ( flag )
					{
						that.allRights.find(p=>p.id === rightId ).style="background-color: #ded; color: #000;";
					}
					else
					{
						delete that.allRights.find(p=>p.id === rightId ).style;
					}
					that.mappedRights[ rightId] = flag;
	    			that.$forceUpdate();
                    resolve (response.data);                    
                 }).catch(e => {
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                    });
	      });
    },
    assignUser(profileId, userId, flag) {
        let that = this;
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/setUserForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/setUserForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profileId+"/"+userId+"/"+flag);
       		HTTP.put( rightsAPI+"/setUserForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profileId+"/"+userId+"/"+flag)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("SET setMappedRights in " + dur +"ms");  
               		for ( var i=0; i < that.users.length; i++)
               		{
               			if ( that.users[i].id === userId )
               			{
               				that.users[i] = response.data;
               				
               			}
               		}
					that.mappedUsers[ userId] = flag;
	    			that.$forceUpdate();
                    resolve (response.data);                    
                 }).catch(e => {
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                    });
	      });
    },
    doUnCopy(myId, fromId, profile, api) {
        let that = this;
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/removeRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/removeRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromId+"/"+profile.id+"/"+myId);
       		api.put( rightsAPI+"/removeRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromId+"/"+profile.id+"/"+myId)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("SET copyMappedRights in " + dur +"ms");  
					that.getMappedRights( 'profile', profile.id);

	    			that.$forceUpdate();
	    			that.showProgressBar = false;
                    resolve (response.data);                    
                 }).catch(e => {
                		that.showProgressBar = false;
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    doCopy(myId, fromId, profile, api) {
        let that = this;
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/copyRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/copyRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromId+"/"+profile.id+"/"+myId);
       		api.put( rightsAPI+"/copyRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromId+"/"+profile.id+"/"+myId)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("SET copyMappedRights in " + dur +"ms");  
					that.getMappedRights( 'profile', profile.id);

	    			that.$forceUpdate();
	    			that.showProgressBar = false;
                    resolve (response.data);     
                                   
                 }).catch(e => {
                        that.showProgressBar = false;
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    action( what, myId, api)
    {
    	this[what](myId, this.copyFromId, this.copyToProfile, api)
    },
    copy()
  	{
  		this.closeCopyConfPU();
  		this.copyFromId = this.dndFromProfile.id;
  		this.copyToProfile = this.dndToProfile;
  	    this.pbAction = "doCopy";
  	    this.showProgressBar = true;
  	},
  	unCopy()
  	{
  		this.closeRemoveConfPU();
  		this.copyFromId = this.dndFromProfile.id;
  		this.copyToProfile = this.dndToProfile;
  	    this.pbAction = "doUnCopy";
  	    this.showProgressBar = true;
  	},
  	
    setAllRights()
  	{
        this.pbAction = "doSetAllRights";
  	    this.showProgressBar = true;
  	},
    doSetAllRights(myId, x, y, api) {
    	let that = this;
    	let profile = this.selectedProfile;
        let time = new Date().getTime();
        var ids = new Array();
        let rights = this.filter(this.allRights);
        for ( var i in rights )
        {
        	ids.push( rights[i].id);
        }
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/copyRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/setRightsForProfileByList/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profile.id+"/"+myId);
       		api.put( rightsAPI+"/setRightsForProfileByList/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profile.id+"/"+myId, ids)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("SET removeRightsForProfile in " + dur +"ms");  
					that.getMappedRights( 'profile', profile.id);
	    			that.$forceUpdate();
	    			that.showProgressBar = false;
                    resolve (response.data);                    
                 }).catch(e => {
                        that.showProgressBar = false;
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    remove(profile) {
    	this.pbAction = "doRemove";
    	//alert(this.pbAction);
  	    this.showProgressBar = true;
    },
    doRemove( myId, x, y, api) {
        let that = this;
        let profile = this.selectedProfile;
        //alert(myId + "//" + profile.label);
        let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
    	    // @Path("/copyRightsForProfile/{tenantId}/{accessPointId}/{unitId}/{userId}/{profileId}")
            //console.log(rightsAPI+"/removeRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profile.id+"/"+myId);
       		api.put( rightsAPI+"/removeRightsForProfile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+profile.id+"/"+myId)
        		 .then( response => 
                 {
                    let dur = (new Date().getTime()-time);
               		//console.log("SET removeRightsForProfile in " + dur +"ms");  
					that.getMappedRights( 'profile', profile.id);
	    			that.$forceUpdate();
	    			that.showProgressBar = false;
                    resolve (response.data);                    
                 }).catch(e => {
                		that.showProgressBar = false;
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    getRights() {
        let that = this;
		
		that.allRights = [];
        let time = new Date().getTime();
    	let rightsLoad = new Promise(function (resolve, reject) {
    	  
            //console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Right/allEntities/true/0");
       		HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Right/allEntities/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		//console.log("LOADED rights in " + dur +"ms");  
                    var allRights = response.data.data;
                    that.rights = [];
                    that.moduls = [];
                    for ( var right in allRights )
                    {
                    	that.allRights.push( allRights[right]);
                    }
                    that.rights = that.allRights;
                    that.rightsMetadata = response.data.metadata;
                    that.rights2DataMap = response.data.entity2DataMap;
                    that.allModuls = response.data.entity2DataMap.Module.data;
                    that.allActions = response.data.entity2DataMap.action.data;
                    for ( var mIDX in response.data.entity2DataMap.Module.data )
                    {
                    	let module = response.data.entity2DataMap.Module.data[ mIDX];
                    	that.moduls[ module.id] = module;
                    }   
                    //alert( rightsAPI+"/getAllScreens/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
                    HTTP.post( rightsAPI+"/getAllScreens/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	        		 .then( response => 
	                 {
	                    //alert( JSON.stringify(response.data));
	                 	that.allScreens = response.data;
	                 	
	                 	resolve (that.rights); 
                 	  }).catch(e => {
                        that.$toast.error("loading screens: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
                                       
                 }).catch(e => {
                        that.$toast.error("loading data for profiles: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
            
	      });
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	update()
	{
		this.startLoader()
   		this.getProfiles();
		this.getRights();
		this.getUsersMD(1);
	}
  },
  created() {
	let mode = isDarkMode()?"dark":"normal";
  	import("@/themes/"+mode+"Theme.scss");  
    this.update();
    initAppMode();
  },
  mounted() {
  	this.focusInput();
  },
  watch: {
  /*
  	fromDate: function() { this.getData() },
  	untilDate: function() { this.getData() },
  	selectedStation: function() { this.getData() }
  	*/
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
	        // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.admin:after {
    content:" [ADM]";
    font-size: 8pt;
    color: red;
 }
.disabledUser {
    text-decoration: line-through;
    color: #888;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.bold {
	font-weight: bold;
}
.invers {
	padding: 4pt; 
	color: #fff; 
	background-color: #444;
}
.profile {
    padding-left: 5pt;
	font-size: 11pt;
}
.profile:hover {
	background-color: #eef;
	color: #000;
}
.right {
    padding-left: 5pt;
	font-size: 11pt;
}
.right:hover {
	background-color: #eef;
	color: #000;
}
.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: normal;
	padding-top: 9pt;
}
.SVcontrolMain {
	font-weight: bold;
	padding-top: 9pt;
}
.todrag {
 display: block;
 width: 100%; 

}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButton:hover {
    background-color: #ccf;
}  
</style>
